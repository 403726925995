import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय ८ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय ८ वा' num='08'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे वसुदेव देवकीनंदना । बाळगोपाळ कृष्णा । तूच हरणार आमची तृष्णा ॥ १ ॥
सोळा सहस्त्र नारींना रमवणारा । त्यात तू न रमणारा । कर्तव्य कर्म जाणणारा । भक्तांच्या हाकेला धावणारा ॥ २ ॥
अर्जुनाची बाजू घेणारा । त्याच्यासाठी लढणारा । त्याला अजिंक्य करणारा । कौरव संहार करणारा ॥ ३ ॥
समय सूचकतेने वागणारा । सदा प्रेरणा देणारा । भक्तांचे रक्षण करणारा । सहाय्यभूत होतोस खरा ॥ ४ ॥
हे देवकीनंदना । सहाय्य असू दे ग्रंथलेखना । तुझ्या सहाय्याविणा । कैसे करू लेखना ? ॥ ५ ॥
शतशः वंदन तुजला । जोड असू दे सरस्वतीला । जी लिहविते ग्रंथाला । तेणे कार्य पूर्णत्वाला ॥ ६ ॥
अवलीया मज उपदेश करतो । विविध प्रसंगी तारतो । अर्जुनास तू सहाय्यभूत होतो । तैसा तो मज ठरतो ॥ ७ ॥
पडलेल्या अर्जुनास । तूच उभे केलेस । कर्म महत्व पटवलेस । कर्म प्रवृत्तीत रमवलेस ॥ ८ ॥
जैसा अर्जुन रणांगणात । तैसा प्रसंग क्षणभरात । नाना उपदेश होतात । मज प्रवृत्त करतात ॥ ९ ॥
कृष्णा ! तूच आता धावावे । ऐसे प्रवृत्त करावे । गुरू आज्ञा पालन व्हावे । जीवन सार्थक करावे ॥ १० ॥
आज्ञेचे महत्व पटते । आज्ञा पालनाते । बोजे हलके होते । परी आज्ञा न पालन होते ॥ ११ ॥
ज्याला तुझे सहाय्य लाभते । त्याचे कार्य पूर्ण होते । ह्याची मला खात्री पटते । जेणे विसंबणे होते ॥ १२ ॥
पुन्हा पुन्हा विनवणी । लिहू दे मला गुरू कहाणी । पडू दे शब्द कानी । जेणे चालेल लेखणी ॥ १३ ॥
तू सांगतो सर्वांस । नका आचरू वैरभावास । जैसे फळ कंसास । तैसेच वैर आचरणार्‍यास ॥ १४ ॥
देशमुखांचे ऐसे होते । वैरभावाशी नाते । पाटिलांचे उदो होण्याते । डोळ्यात सुख खुपते ॥ १५ ॥
गुरू कृपा पाटिलांवर । होती निर्विवाद थोर । पाटिलांच्या ओसरीवर । नांदू लागला पोर ॥ १६ ॥
बोलबाला पाटिलांचा । देशमुखा न पटे साचा । गाव शेगाव दुफळीचा । मार्ग वैमनस्याचा ॥ १७ ॥
दोन फड गावात । एक देशमुखात । दुसरा पाटिलात । जो तो कुरापती काढण्यात ॥ १८ ॥
शुद्ध अंतःकरण । नव्हते देशमुखाकारण । तैसीच कृतीची घडण । कुरापतीस कारण ॥ १९ ॥
शेगावात दोन पक्ष । एक पाटिल दुसरा देशमुख । कैसे असणार त्यांत सख्य ? । सदा त्यांच्यात वैमनस्य ॥ २० ॥
एकमेकांच्या उणीव पहाती । केव्हा सत्ता येते हाती । जेव्हा पाटिलांकडे सत्ता होती । देशमुख कुरापती काढती ॥ २१ ॥
हे नेते ऐसे असतात । प्रत्यक्ष घटनेत । कधी नाही भाग घेत । ते चतुर असतात ॥ २२ ॥
ते दूर पळून जातात । दुसर्‍यास चालना देतात । त्यास प्रवृत्त करतात । कुरापतीत रमवतात ॥ २३ ॥
कर्म जरी अप्रत्यक्ष होते । तरी भोगावेच लागते । नेते न सुटणार त्याते । कर्मफळ भोगावेच लागते ॥ २४ ॥
भोगावे लागते ज्याचे त्याला । कुणी न येई मदतीला । म्हणुनच आचरावे सत्कर्माला । जेणे पात्र ठराल आनंदाला ॥ २५ ॥
दुसर्‍यामुळे सत्ताधीश होतो । हेच सत्ताधीश विसरतो । तो सत्तेने उन्मत्त होतो । भलतेच धाडस करतो ॥ २६ ॥
पाटिल असता तळ्यावर । भेटे देशमुखाचा महार । महार पाटिलाचा ताबेदार । महारास ऐकावे लागणार ॥ २७ ॥
पाटिल हुकूम करतो त्यास । घेऊन सरकारी कागदास । जावे तू अकोल्यास । महार नकार देतो त्यास ॥ २८ ॥
बरीच होते बोलाचाली । त्यातही शिव्यागाळी । खंडूस भारी चीड आली । म्हणे महाराने अवज्ञा केली ॥ २९ ॥
महाराने दिला नकार । पाटिल खवळला फार । क्रोध झाला अनावर । सोटा मारला हातावर ॥ ३० ॥
महाराचा हात मोडला । देशमुखाने डाव साधला । संधीचा फायदा घेतला । फौजदारी दावा केला ॥ ३१ ॥
विरोधीपक्ष काय करणार ? । ऐसी सुवर्णसंधीच साधणार । दुसर्‍यास दिलासा देणार । आपले बळ वाढवणार ॥ ३२ ॥
कुकाजी निवर्तल्यावर । खंडू पाहे कारभार । देशमुखास ते सलणार । तो काटाच काढणार ॥ ३३ ॥
शिपायास केले आपले । प्रसंग फुलवून सांगितले । स्वरूप मोठे केले । पाटिलांस गुन्हेगार ठरवले ॥ ३४ ॥
एरव्ही शिपायास न महत्व । फौजदारी खटल्यात त्याचेच वर्चस्व । शिपायाची करता आर्जव । त्रासातून सुटतो जीव ॥ ३५ ॥
न्यायाधीश निकाल देई पावेतो । शिपाई गुन्हेगारास सतावतो । कर्तव्य चोख बजावतो । एरव्ही दुर्लक्ष करतो ॥ ३६ ॥
येथे असेच झाले । पाटिल गुन्हेगार झाले । पाटिल पुढचे सर्व समजले । तोंडचे पाणी पळाले ॥ ३७ ॥ 
परी गुरूकृपा असल्यावर । क्लेश कमी होणार । पाटिलांचा भार समर्थांवर । त्यांनी सोपवले समर्थांवर ॥ ३८ ॥
खंडू पाटिलावर वॉरंट निघाले । देशमुख आनंदित झाले । पुढचे दृष्य दिसू लागले । सत्तेचे मनोराज्य बघू लागले ॥ ३९ ॥
पाटिल मनोमनी घाबरले । काट्याचे नायटे झाले । काही सुचेनासे झाले । बंधू अकोल्यास गेले ॥ ४० ॥
महाराजांचे स्मरण केले । तेच आता उरले । पाटिल रात्रीच मंदिराकडे निघाले । समर्थ चरणी मस्तक ठेवले ॥ ४१ ॥
खंडू मुळचा द्वाड अती । त्यात सत्तेचा दोर हाती । क्रोधाच्या भरात कृती । मग पश्चाताप अती ॥ ४२ ॥
विघ्नहर्त्याला दया येते । तेणे संबोधिले जाते । महाराज विघ्नहर्ता होते । दयेचे पाझर फुटत होते ॥ ४३ ॥
महाराजांचे चरण धरले । महाराजांनी आशिष दिले । खंडूस समजाविले । दोन्ही हातांनी कवटाळले ॥ ४४ ॥
जा भिऊ नकोस रतीभर । बेडी न तुज पडणार । देशमुखाचे न चालणार । तू निर्दोष सुटणार ॥ ४५ ॥
विधात्याने महाराजांचे । निश्चित केले जगण्याचे । महत्व पूर्व संचिताचे । तैसेच होते जगण्याचे ॥ ४६ ॥
महत्वाचे प्रसंग निश्चित । त्यांना तोंड देण्यात । दुसरी कर्मे घडतात । पुढच्यास कारक होतात ॥ ४७ ॥
कोण थोपवणार विधात्याला ? । तसेच कर्म प्रवृत्तीला ? । समर्थही न सुटती त्याला । विविध कर्मे आचरणाला ॥ ४८ ॥
ऐन दुपारच्या वेळी । समर्थ निवांतपणे झोपले त्यावेळी । तेलंगी ब्राह्मण मंडळी । खंडूच्या घरी आली ॥ ४९ ॥
भिक्षा मागण्यास आली । मंत्रोच्चार करू लागली । इकडे तिकडे बघू लागली । मोठ्याने म्हणू लागली ॥ ५० ॥
ज्याच्या घरी समर्थ । त्याच्या घरचे स्वस्थ । कुणीही येता त्रयस्थ । न कधी भयग्रस्त ॥ ५१ ॥
समर्थांनी सारे ऐकले । मंत्रोच्चार सुधारले । निद्रिस्तांना जागे केले । तेलंगी थक्क झाले ॥ ५२ ॥
समर्थे त्यांना सावध केले । लुटू नका बजावले । फुलाची पाकळी देवविते झाले । पुढे जाण्यास सांगितले ॥ ५३ ॥
ब्राह्मणांनी भिक्षा घेतली । त्यांच्या झोळीत कोंबली । पुढच्या घरची वाट धरली । हीच पथ्यावर पडली ॥ ५४ ॥
चुका दुरुस्त करायचे । सन्मार्गाला लावायचे । हेच झाले नित्याचे । नाव नव्हते स्वस्थतेचे ॥ ५५ ॥
योग्यास घर एकाचे । रात्रंदिवस न चाले साचे । कधी स्थिर नसायचे । नवे घर शोधायचे ॥ ५६ ॥
सारखा मुक्काम बदलायचे । वैभवात न रमायचे । असेच असते योग्याचे । जे होते समर्थांचे ॥ ५७ ॥
खंडू कडून निघाली स्वारी । आली कृष्णाजीच्या मळ्यावरी । आनंद झाला भारी । हास्य खिळले गालावरी ॥ ५८ ॥
जेथे महाराज जाणार । तेथे ऐसेच होणार । हा नव्हता पाहुणचार । त्यांना हवा होता आधार ॥ ५९ ॥
जो दुसर्‍यावर अवलंबतो । त्याचीच मनधरणी करतो । त्याच्याकडून काम साधतो । जीवन सार्थक करतो ॥ ६० ॥
सेवा करून मेवा खाऊन । हीच बालपणी शिकवण । सेवा करावी मनोमन । जेणे रहाल मेवा खाऊन ॥ ६१ ॥
मेव्यासाठी का होईना ।  सत्कर्म घडते त्या क्षणा । नुसते कधी पटेना । आमिष दावल्याविणा ॥ ६२ ॥
समर्थे कृष्णाजीस । आज्ञा केली त्यास । झोपडीच दे आम्हास । नको कुणाच्या घरास ॥ ६३ ॥
शंकराच्या देवळाजवळी । झोपडीची सोय केली । काही दिवस रहाण्या आली । गुरूमूर्ती तेथे वसली ॥ ६४ ॥
महाराजांस वाटले । उपाधीस टाळले । प्रारब्धात लिहिलेले । न कधी टळले ॥ ६५ ॥
गावात वृत्त समजले । मळ्यावर सारे धावले । हेतू साध्य करू लागले । कृपाशिष घेऊ लागले ॥ ६६ ॥
समर्थांनी वास केले । मळ्याचे क्षेत्र झाले । स्थान महात्म्य वाढले । फुलहार नवस झाले ॥ ६७ ॥
समर्थांबरोबर । पाटिल भास्कर । तुकाराम कोकाटे बरोबर । सेवा करण्यात निरंतर ॥ ६८ ॥
समर्थांच्या लीला । दूरवर पसरल्या । भावुक भक्त होती गोळा । कृष्णाजीचा फुलला मळा ॥ ६९ ॥
दूरच्या गोसाव्यांनी । फायदा उठवावा या हेतूनी । यावे शेगावी त्यांनी । यात्रेचे नाव काढुनी ॥ ७० ॥
दहा-वीस गोसाव्यात । ब्रह्मगिरी मुख्य त्यात । ढोंगी अंतरंगात । फिरतो साधू वेषात ॥ ७१ ॥
गोसावी म्हणती पाटिलासी । आम्ही निघालो यात्रेसी । जातो रामेश्वरासी । घेउनी येतो भागिरथीसी ॥ ७२ ॥
धर्म प्रचारास्तव । फिरतो गावोगाव । हृदयात भोळा भाव । जेणे धार्मिकतेला वाव ॥ ७३ ॥
गोसावी म्हणे कृष्णाजीस । मुक्काम तीन दिवस । तू घरचा श्रीमंत दिसतोस । खाऊ घाल शिरा पुरीस ॥ ७४ ॥
तू नंग्यास पोसतोस । वेडा पिसा बाळगतोस । आम्ही आलो प्रवचनास । धर्म जागृती करण्यास ॥ ७५ ॥
मुक्काम करावा मळ्यावर । आज्ञा आम्हा झाल्यावर । आलो येथवर । आसरा द्यावा सत्वर ॥ ७६ ॥
कृष्णाजी म्हणे त्यावर । आज सायंकाळास । ऐकीन प्रवचनास । मग पंचपक्वान्नास । घालीन भोजनास ॥ ७७ ॥
गांजास येथे कमी नाही । तैनातीस उणीव नाही । जो आपले म्हणुन राही । त्याचीच सोय होई ॥ ७८ ॥
सायंकाळी प्रवचनास । गीता श्र्लोक निरूपणास । ब्रह्मगीरीने समजावण्यास । घेतले आधारास ॥ ७९ ॥
प्रवचन रंगले नाही । गावकरी रमले नाही । कुजबुज करत राही । साधूस न लवलेशही ॥ ८० ॥
निराश एक एक झाल्यावर । परतू लागला झोपडीवर । महाराज होते पलंगावर । जवळ होता भास्कर ॥ ८१ ॥
तो होता चिलिम भरत । महाराज आपल्या तंद्रीत । गावकरी सारे वृत्त पहात । समजले त्यांच्या मनात ॥ ८२ ॥
गोसाव्याच्या गर्दीला । उतार पडू लागला । गोसाव्यास राग आला । त्याचा अपमान झाला ॥ ८३ ॥
ज्याच्या जवळ नाही तप । तो काय देणार श्राप ? । खरा न ठरे श्राप । कुणाचा न थरकाप ॥ ८४ ॥
शब्द ज्याचे धारदार । त्यालाच घाबरणार । मिळमिळत्या शब्दावर । न थरकाप होणार ॥ ८५ ॥
इकडे समर्थांपाशी । भास्कर होता सेवेशी । देत होता चिलिमीसी । वरचेवर त्यांसी ॥ ८६ ॥
त्या चिलिमीच्या विस्तवाची । ठिणगी पडली त्याची । नेमकी पलंगावर पडण्याची । परी दृष्टी न गेली कुणाची ॥ ८७ ॥
काही काळ लोटल्यावर । निघू लागला धूर । हळू हळू चौफेर । ज्वाळा निघाल्या साचार ॥ ८८ ॥
भास्कर जवळ होता । तो म्हणे सद्गुरूनाथा । बसू नका येथे आता । धरू लागला त्यांच्या हाता ॥ ८९ ॥
क्षणात पलंग पेटला । सर्वत्र हाहाकार माजला । कृष्णाजीही घाबरला । जणू स्वतःचाच पलंग पेटला ॥ ९० ॥
भास्करे उठवले महाराजांना ।महाराज मुळचे हालेना । विनवण्या जरी नाना । समर्थांना पटेना ॥ ९१ ॥
अग्नी पंचमहाभुतांतला । देहही पंचमहाभुतांतला । जरी अग्नीने धर्म आचरला । मी न घाबरणार त्याला ॥ ९२ ॥
मी येथेच बसणार । पलंग पेटला सत्वर । ज्वाळा निघाल्या वरचेवर । जणू समर्था करण्या स्वाहाकार ॥ ९३ ॥
समर्थ वदले भास्करास । आण ब्रह्मगीरीस । जळत्या पलंगावर बसण्यास । गीता तत्व पचवण्यास ॥ ९४ ॥
गुरू आज्ञा प्रमाण झाली । ब्रह्मगिरीची बखोट धरली । समर्थांपुढे स्वारी आणली । ब्रह्मगीरीची मुंडी खाली ॥ ९५ ॥
ब्रह्मगिरी पक्का दांभिक जाण । गेला मनात घाबरून । वाटे जावे पळुन तेथून । घाम सुटला दरदरून ॥ ९६ ॥
समर्थांस शरण आला । माफी मागू लागला । उपदेशीले ब्रह्मगिरीला । मिरवू नको पांडित्याला ॥ ९७ ॥
भलते जगा सांगू नये । आधी करावे नि सांगावे । कृतीनेच पटवावे । वायफळ बडबडू नये ॥ ९८ ॥
ब्रह्मगिरी वदे समर्थांप्रत । मी पोटभर्‍या संत । नुसत्या पांडित्यात । अनुभवले न काही त्यात ॥ ९९ ॥
उगाच पिसा म्हणालो । अपशब्द बोललो । पंचपक्वान्नात रमलो । उगाच व्यर्थ हिंडलो ॥ १०० ॥
पुन्हा एकदा जनांनी । समर्थां केली विनवणी । भक्तांची विनवणी ऐकुनी । समर्थ उतरले त्या क्षणी ॥ १०१ ॥
खाली उतरता गजानन । पलंग गेला कोसळून । जो शेषभाग त्या लागून । लोकांनी टाकला विझवून ॥ १०२ ॥
मध्यरात्रीच्या समयाला । बोलले ब्रह्मगिरीला । ज्याने राख फासली अंगाला । त्याने आचरू नये ढोंगाला ॥ १०३ ॥
समर्थ स्वामी रामदास । ज्ञानेश्वर तुकारामास । गोरक्ष मच्छिन्द्रनाथास । जाणावे तत्वास ॥ १०४ ॥
उगाच पांडित्यावरी । फिरू नकोस भूमीवरी । अहंकार नको उरी । निंदा थट्टा मस्करी ॥ १०५ ॥
त्याची कान उघाडणी । केली समर्थांनी । पटले त्यास मनोमनी । पश्चाताप झाला मनी ॥ १०६ ॥
राहू नकोस ह्या स्थळास । रहाता फजितीच्या प्रसंगास । नाही ओळखले मालकास । तो बडवेल तुम्हांस ॥ १०७ ॥
ब्रह्मगिरीस पटले । त्याचे डोळे उघडले । तेथून निघणे नक्की केले । पुढच्या वाटेस अनुसरले ॥ १०८ ॥
 
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य अष्टोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
